// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("bootstrap")
require("ekko-lightbox")

import $ from 'jquery';
global.$ = jQuery;

$(document).ready(function() {
  handleiFrameResponsiveness();

  $(document).on('change', "#participant_contact_via", function(){
    var current = $(this).val();
    var target = $("#participant_contact_detail");
    var prefix_target = $("#participant_contact_prefix");
    var contact_prefix = $("#col_participant_contact_prefix");
    var contact_detail = $("#col_participant_contact_detail");
    if(current == 'Skype'){
      contact_prefix.addClass('d-none');
      contact_detail.removeClass('d-none');
      target.attr('placeholder', 'Skype Username');
    }else if ((current == 'WhatsApp') || (current == 'Viber')){
      contact_prefix.removeClass('d-none');
      contact_detail.removeClass('d-none');
      prefix_target.attr('placeholder', '+91');
      target.attr('placeholder', '(111) 111 111');
    }else{
      contact_prefix.addClass('d-none');
      contact_detail.addClass('d-none');
    }

  });

  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });

  $('#ssrf-event-about img').addClass('img-fluid');
});

function handleiFrameResponsiveness(){
  var iframe = $("#ssrf-event-about iframe");
  iframe.parent().addClass('embed-responsive embed-responsive-16by9')
  iframe.addClass("embed-responsive-item");
};
